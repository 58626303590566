@import '../../pages/page-include';

.image-content {
    position: relative;
    height: 100%;

    &.image-content--fixed-height {
        .image-content__content {
            @include mq($mobile-max) {
                position: relative;
            }
        }
    }
}

.image-content__content {
    @include absolute-full-width;
    @include scrollbar;

    padding: 80px;
    text-align: var(--text-align-d);
    overflow-y: auto;
    word-break: break-word;

    &.light {
        color: $white;
    }

    &.dark {
        color: $black;
    }

    @include mq($tablet-max) {
        padding: 30px;
    }

    @include mq($mobile-max) {
        text-align: var(--text-align-m);
    }

    &.image-content__content--flex {
        display: flex;
        flex-direction: column;
    }
}

.image-content__image {
    width: 100%;
    object-fit: cover;
    object-position: var(--focal-point-x) var(--focal-point-y);
    display: block;
}

.image-content__link-overlay {
    @include absolute-full-width;

    display: block;
    z-index: 2;

    &::before {
        @include image-contrast-overlay;

        opacity: 0;
        transition: 0.5s ease;
    }

    &:hover {
        &::before {
            opacity: 0.25;
        }
    }
}

.image-content__content-bg {
    position: absolute;
    width: 220px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: $white;
    padding: 10px 30px;
    color: $black;
}

.image-content__content-bg__text {
    margin: 0;
}

.image-content__content-bg__text-inner {
    display: block;
}

.image-content__title {
    margin-bottom: 30px;

    @include mq($not-mobile-tablet) {
        margin-bottom: 50px;
    }

    @include mq($tablet-only) {
        font-size: 26px;
        line-height: 30px;
    }

    @include mq($mobile-max) {
        font-size: 22px;
        line-height: 26px;
    }
}

.image-content__title-span {
    display: block;
}

.image-content__link {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
    display: block;

    @include mq($tablet-max) {
        font-size: 18px;
        line-height: 22px;
    }
}

.image-content__text {
    margin-bottom: 15px;

    p,
    h3,
    h4 {
        font-size: 24px;
        line-height: 28px;
    }

    h1,
    h2,
    h3,
    h4,
    p {
        margin-bottom: 15px;
    }

    &.light {
        h1,
        h2,
        h3,
        h4,
        p,
        span,
        strong {
            color: $white !important;
        }
    }

    &.dark {
        h1,
        h2,
        h3,
        h4,
        p,
        span,
        strong {
            color: $black !important;
        }
    }

    @include mq($tablet-max) {
        p,
        h3,
        h4 {
            font-size: 18px;
            line-height: 22px;
        }

        h2 {
            font-size: 22px;
            line-height: 26px;
        }
    }
}

.image-content__icon {
    margin-top: auto;
}
