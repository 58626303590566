/************ Skandinavisk colors ***********/
/******* Aspect ratios *****/
/****** Transition *******/
.image-content {
  position: relative;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .image-content.image-content--fixed-height .image-content__content {
    position: relative;
  }
}

.image-content__content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  scrollbar-color: #000 #fff;
  scrollbar-width: thin;
  padding: 80px;
  text-align: var(--text-align-d);
  overflow-y: auto;
  word-break: break-word;
}
.image-content__content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.image-content__content::-webkit-scrollbar-thumb {
  background: #000;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
.image-content__content::-webkit-scrollbar-track {
  background: #fff;
}
.image-content__content.light {
  color: #fff;
}
.image-content__content.dark {
  color: #000;
}
@media only screen and (max-width: 1199px) {
  .image-content__content {
    padding: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .image-content__content {
    text-align: var(--text-align-m);
  }
}
.image-content__content.image-content__content--flex {
  display: flex;
  flex-direction: column;
}

.image-content__image {
  width: 100%;
  object-fit: cover;
  object-position: var(--focal-point-x) var(--focal-point-y);
  display: block;
}

.image-content__link-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  z-index: 2;
}
.image-content__link-overlay::before {
  background-color: #fff;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.15;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: 0.5s ease;
}
.image-content__link-overlay:hover::before {
  opacity: 0.25;
}

.image-content__content-bg {
  position: absolute;
  width: 220px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #fff;
  padding: 10px 30px;
  color: #000;
}

.image-content__content-bg__text {
  margin: 0;
}

.image-content__content-bg__text-inner {
  display: block;
}

.image-content__title {
  margin-bottom: 30px;
}
@media only screen and (min-width: 1200px) {
  .image-content__title {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .image-content__title {
    font-size: 26px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .image-content__title {
    font-size: 22px;
    line-height: 26px;
  }
}

.image-content__title-span {
  display: block;
}

.image-content__link {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
  display: block;
}
@media only screen and (max-width: 1199px) {
  .image-content__link {
    font-size: 18px;
    line-height: 22px;
  }
}

.image-content__text {
  margin-bottom: 15px;
}
.image-content__text p,
.image-content__text h3,
.image-content__text h4 {
  font-size: 24px;
  line-height: 28px;
}
.image-content__text h1,
.image-content__text h2,
.image-content__text h3,
.image-content__text h4,
.image-content__text p {
  margin-bottom: 15px;
}
.image-content__text.light h1,
.image-content__text.light h2,
.image-content__text.light h3,
.image-content__text.light h4,
.image-content__text.light p,
.image-content__text.light span,
.image-content__text.light strong {
  color: #fff !important;
}
.image-content__text.dark h1,
.image-content__text.dark h2,
.image-content__text.dark h3,
.image-content__text.dark h4,
.image-content__text.dark p,
.image-content__text.dark span,
.image-content__text.dark strong {
  color: #000 !important;
}
@media only screen and (max-width: 1199px) {
  .image-content__text p,
.image-content__text h3,
.image-content__text h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .image-content__text h2 {
    font-size: 22px;
    line-height: 26px;
  }
}

.image-content__icon {
  margin-top: auto;
}